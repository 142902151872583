import Service from "./Service";
const baseurl = '/api/formula-medica';
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showFormulaByHc(id_historia) {
        return Service.get(`${baseurl}/show-formula-hc/${id_historia}`);
    },
    showFormulaById(id) {
        return Service.get(`${baseurl}/show-formula-byId/${id}`);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    /* getAll(id_usuario) {
        return Service.get(`${baseurl}/show-formulas-anteriores/${id_usuario}`);
    }, */
    async getAll(search = '',pagination = {},id) {
      
        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(pagination['per_page'] ? {per_page : pagination['per_page']} : {}),
            ...(pagination['page'] ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };

        return Service.get(`${baseurl}/show-formulas-anteriores/${id}?`, {
            params : {
                ...params
            }
        });
    },
}