<template>
    <div class="modal fade" id="modalFormulasA" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Formulas</h5>
                    <button type="button" class="close" @click="closeModal()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning mt-2" v-if="!pagination.data.length">
                        Sin formulas anteriores...
                    </div>
                    <data-grid :data="pagination" @changePage="changePage($event)"
                        @perPageChange="perPageChange($event)" @search="search($event)" :searchBox="false">
                        <template #grid>
                            <div class="card mt-2 border-gray-500 shadow-none card-header-actions mb-1"
                                v-for="(form) in pagination.data" :key="form.id">
                                <div class="card-header text-black font-wight-bolder">
                                    <div class="custom-control custom-switch">
                                        <input :id="`check_formula_${form.id}`" v-model="form.checked"
                                            class="custom-control-input" type="checkbox">
                                        <label :for="`check_formula_${form.id}`" class="custom-control-label">#{{form.id }}</label>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <span class="font-weight-bolder text-success mx-2"><i class="fa fa-calendar-alt fa-fw"></i>{{form.historia.fecha | formatDate }}</span>
        
                                        <span><i class="fa fa-user-md fa-fw"></i>{{ form.historia.user.name }}</span>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row mx-0 mb-3" v-for="(item, index) in form.items" :key="index">
                                        <div class="col-lg-12 col-sm-12 mb-3">
                                            <div class="font-weight-bold">
                                                {{ item.servicio.codigo }}
                                                {{ item.servicio.descripcion }}
                                                <div class="small text-muted d-none d-md-block">
                                                    F. farmaceutica: {{ item.presentation }} , Via admin : {{item.route_administration }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group  col-md-12 col-lg-2 col-sm-12 col-xs-12 ">
                                            <label class="form-label small mb-0">Cantidad <small
                                                    class="text-danger">*</small></label>
                                            <input type="number" class="form-control form-control-sm"
                                                v-model="item.dosis">
                                        </div>
                                        <div class="form-group col-md-12 col-lg-2 col-sm-12 col-xs-12">
                                            <label class="form-label small mb-0">U. medida </label>
                                            <input class="form-control form-control-sm" v-model="item.unity" readonly>
                                        </div>

                                        <div class="form-group col-md-12 col-lg-3 col-sm-12 col-xs-12">
                                            <label class="form-label small mb-0">Cada <small
                                                    class="text-danger">*</small></label>
                                            <div class="input-group">
                                                <input type="number" v-model="item.frecuencia"
                                                    class="form-control form-control-sm ">
                                                <div class="input-group-append">
                                                    <select class="form-control form-control-sm form-control-solid"
                                                        v-model="item.unidad">
                                                        <option value="horas">Hora(s)</option>
                                                        <option value="dias">Dia(s)</option>
                                                        <option value="minutos">Minuto(s)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12 col-lg-3 col-sm-12 col-xs-12">
                                            <label class="form-label small mb-0">Por <small
                                                    class="text-danger">*</small></label>
                                            <div class="input-group">
                                                <input type="number" class="form-control form-control-sm"
                                                    v-model="item.duracion">
                                                <div class="input-group-append">
                                                    <select class="form-control form-control-sm form-control-solid"
                                                        v-model="item.duracion_unity">
                                                        <option value="horas">Hora(s)</option>
                                                        <option value="dias">Dia(s)</option>
                                                        <option value="minutos">Minuto(s)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12 col-lg-2 col-sm-12 col-xs-12">
                                            <label class="form-label small mb-0">Via adm. <small
                                                    class="text-danger">*</small></label>
                                            <input class="form-control form-control-sm"
                                                v-model="item.route_administration" readonly>
                                        </div>
                                        <div class="form-group col-sm-12 col-xs-12 col-md-12 col-lg-2">
                                            <label class="form-label small mb-0">
                                                Total
                                                <a class="pop_help"
                                                    data-content="El sistema calcula el total de dosis de forma automatica en los casos de medicamentos como(TABLETA,OVULOS,CAPSULAS,AMPOLLA,UNIDAD), por favor verifique que el total sea correcto. si no es asi, entonces edite este valor"
                                                    data-toggle="popover" tabindex="0" title="Total">
                                                    <i class="fas fa-question-circle"></i>
                                                </a>
                                            </label>
                                            <input v-model="item.total" class="form-control form-control-sm"
                                                type="number" />
                                        </div>

                                        <div class="form-group col-md-12 col-sm-12 col-lg-8">
                                            <label class="form-label mb-0">Indicaciones <small
                                                    class="text-danger">*</small></label>
                                            <input type="text" class="form-control form-control-sm"
                                                v-model="item.indications">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </template>
                    </data-grid>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-sm" @click="proceduresSelected()">
                        <i class="fa fa-copy fa-fw"></i> Copiar </button>
                    <button type="button" class="btn btn-light btn-sm" @click="closeModal()"><i
                            class="far fa-times-circle fa-fw"></i>
                        Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import formulaMedicaService from '../../../../services/formulaMedicaService';
import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
//import {required } from "vuelidate/lib/validators";
import Toast from '../../../../components/common/utilities/toast';
export default {
    props: ['idHistoria', 'usuario'],
    components: { DataGrid },
    data() {
        return {
            selectedExams: [],
            formulas: [],
            presentations: [
                'TABLETA', 'JARABE', 'CREMA', 'CAPSULA', 'AMPOLLA', 'SUSPENSION', 'EMULSION', 'SUPOSITORIO',
                'OVULO', 'CREMA', 'GEL', 'INHALADOR', 'GOTAS', 'NEBULIZADOR', 'SOLUCION', 'UNIDAD', 'NO APLICA', 'LOCION', 'SOBRES'
            ],
            measurement_units: [
                'CC', 'TAB',
                'GOTA(S)', 'AMPOLLA(S)', 'PUFF(S)',
                'GRAMO(S)', 'CAPSULA(S)', 'UNIDAD(ES)',
                'OVULO(S)', 'Mcg', 'APLICACION', 'NA', 'ML', 'SOBRE'
            ],
            routes_of_administration: [
                'ORAL', 'TOPICA', 'OFTALMICA', 'SUBLINGUAL', 'TRANSDERMICA', 'OTICA', 'INTRANASAL',
                'INHALATORIA', 'RECTAL', 'VAGINAL', 'INTRAMUSCULAR', 'SUBCUTANEA', 'INTRAVENOSA', 'NO APLICA'
            ],
            formasUnidades: {
                'TABLETA': 'TAB',
                'JARABE': 'ML',
                'CREMA': 'APLICACION',
                'CAPSULA': 'CAPSULA(S)',
                'AMPOLLA': 'AMPOLLA(S)',
                'SUSPENSION': 'CC',
                'EMULSION': 'CC',
                'SUPOSITORIO': 'TAB',
                'OVULO': 'OVULO(S)',
                'GEL': 'APLICACION',
                'INHALADOR': 'PUFF(S)',
                'GOTAS': 'GOTA(S)',
                'NEBULIZADOR': 'PUFF(S)',
                'SOLUCION': 'CC',
                'UNIDAD': 'UNIDAD(ES)',
                'NO APLICA': 'NA',
                'LOCION': 'APLICACION',
                'SOBRES': 'SOBRE',
            },
            idUsuario: '',
            pagination: {
                data: [],
                current_page: 1,
                page: 1,
                per_page: 5,
                search: '',
                total: '',
                params: {
                    id_user: "",
                    fecha: "",
                },
            },
        }
    },
    /*  validations(){
         return {
             formulas: {
                 $each: {
                     items: {
                         dosis: { required },
                         unity: { required },
                         frecuencia: { required },
                         unidad: { required },
                         duracion: { required },
                         duracion_unity: { required },
                         total: { required },
                         indications: { required }
                     }
                 }
             }
         }
     }, */
    methods: {
        async openModal(idUsuario) {
            $('#modalFormulasA').modal('show');
            this.idUsuario = idUsuario;
            try {
                this.LoaderSpinnerShow();

                const response = (await formulaMedicaService.getAll(this.pagination.search, this.pagination, idUsuario))?.data;

                this.pagination.data = response.data.map(x => {
                    return {
                        id: x.id,
                        historia: x.historia,
                        items: x.items.map(item => {
                            return {
                                id: item.id,
                                servicio: item.servicio,
                                dosis: item.dosis,
                                unity: item.measurement_unity,
                                route_administration: item.route_of_administration,
                                presentation: item.presentation,
                                indications: item.indications,
                                frecuencia: item.frecuencia,
                                duracion: item.duracion,
                                total: item.total,
                                unidad: item.unidad_frecuencia,
                                duracion_unity: item.unidad_duracion
                            }
                        }),
                        checked: false
                    }
                });
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }

        },
        proceduresSelected() {
            let itemsFormulas = [];

            this.pagination.data.forEach(e => {
                if (e.checked) {
                    itemsFormulas.push(...e.items);
                }
            });

            this.$emit("cargar-formulas", itemsFormulas);
            $('#modalFormulasA').modal('hide');
        },
        closeModal() {
            $('#modalFormulasA').modal('hide');
        },
        changePage(page) {
            this.pagination.page = page;
            this.cargar(this.idUsuario);
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.cargar(this.idUsuario);
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.cargar(this.idUsuario);
        },
        async cargar(idUsuario) {
            try {
                this.LoaderSpinnerShow();
                const response = (await formulaMedicaService.getAll(this.pagination.search, this.pagination, idUsuario))?.data;

                this.pagination.data = response.data.map(x => {
                    return {
                        id: x.id,
                        historia: x.historia,
                        items: x.items.map(item => {
                            return {
                                id: item.id,
                                servicio: item.servicio,
                                dosis: item.dosis,
                                unity: item.measurement_unity,
                                route_administration: item.route_of_administration,
                                presentation: item.presentation,
                                indications: item.indications,
                                frecuencia: item.frecuencia,
                                duracion: item.duracion,
                                total: item.total,
                                unidad: item.unidad_frecuencia,
                                duracion_unity: item.unidad_duracion
                            }
                        }),
                        checked: false
                    }
                });
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },

    },

}
</script>
